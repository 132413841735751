:root{
    --navigation-width:265px;
    --help-page-width:20px;
    --theme-section-bg:#fff;
    --theme-title-color:#333;
    --theme-default-bg:#f9f9f9;
    --theme-default-selected-bg:  #fff;
    --theme-body-bg:#F7F8FA;
    --theme-default-border-color:#eee;
    --theme-default-font-color:#35bed4;
    --theme-primary-hover-bg:  #35bed4;
    --theme-primary-hover-border-color:  #35bed4;
    --theme-link-hover-color:  #35bed4;
    --theme-weak-link-hover-color:  #35bed4;
    --theme-primary-bg:  #15bad4;
    --theme-primary-border-color:  #15bad4;
    --theme-link-color:  #15bad4;
    --theme-primary-active-bg:  #1198ad;
    --theme-primary-active-border-color:  #1198ad;
    --theme-link-active-color:  #1198ad;
    --theme-weak-link-active-color:  #1198ad;
    --theme-rgb-default-selected-bg:  230, 252, 255;
    --theme-rgb-primary-hover-bg:  53, 190, 212;
    --theme-rgb-primary-hover-border-color:  53, 190, 212;
    --theme-rgb-link-hover-color:  53, 190, 212;
    --theme-rgb-weak-link-hover-color:  53, 190, 212;
    --theme-rgb-primary-bg:  21, 186, 212;
    --theme-rgb-primary-border-color:  21, 186, 212;
    --theme-rgb-link-color:  21, 186, 212;
    --theme-rgb-primary-active-bg:  17, 152, 173;
    --theme-rgb-primary-active-border-color:  17, 152, 173;
    --theme-rgb-link-active-color:  17, 152, 173;
    --theme-rgb-weak-link-active-color:  17, 152, 173;
}
body{
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei", STHeiti, SimSun, sans-serif  !important;
    font-weight: bold;
}
body {
    padding: 0;
    margin: 0;
}
.form-cell{
    display: flex;
}
.form-cell-hd{
    flex-basis: 120px;
    justify-content: flex-end;
    text-align: right;
    padding-top: 4px;
}
.form-cell-bd{
    margin-left: 8px;
}
.form-cell-bd .zent-tree-bar--selected{
    background: none !important;
}
.form-submit{
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 1px -2px 8px #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
    height: 60px;
    background: #fff;
}
.app-fixed-container{
    left: var(--navigation-width)!important;
    right: var(--help-page-width)!important;
}
.role-from-textarea{
    min-width: 80px;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: horizontal;
    margin-left: 0px;
    margin-right: 0px;
    height: 54px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: 0;
}
.grid-action-cells{
   text-align: center;
}
.grid-action-cells span{
    padding: 0 8px;
    border-right: 1px solid #E0E0E0;
}
.grid-action-cells span:last-child{
    border: none;
}