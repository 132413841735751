.login-page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;

}
.login-header{}
.login-body{
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}
.login-body-box{
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}
.login-body-box-hd{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
}
.login-body-box-bd{

}
.login-body-box-bd-cells{
    min-width: 400px;
}
.login-body-box-bd-cell{
    display: flex;
    align-items: center;
    padding: 10px;
}
.login-body-box-bd-cell-hd{
    padding: 0 20px;
}
.login-body-box-bd-cell-bd{
    flex: 1;
}
.login-body-box-ft{
    padding: 10px;
    text-align: right;
}
.login-footer{}