.grid-avatar{

}
.grid-avatar-hd{
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-avatar-hd img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: block;
}
.grid-avatar-bd{
    line-height: 20px;
    font-size: 12px;
    text-align: center;
}