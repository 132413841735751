.customer-page{
    background-color: #f5f5f5;
    min-height: 100vh;
}
.customer-body{
    padding-bottom: 60px;
}
.customer-body-cells{
    padding:0 10px;
}
.customer-body-cell{
    padding-top: 10px;
}
.customer-body-cell-box{
    display: flex;
}
.customer-body-cell-hd{
    padding: 0 5px;
}
.customer-body-cell-hd img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
}
.customer-body-cell-bd{
    flex: 1;
}
.customer-body-cell-bd-hd{
    padding: 5px 0;
}
.customer-body-cell-bd-bd{

}
.customer-body-cell-bd-ft{
    color: #999;
    font-size: 12px;
    padding: 10px;
    text-align: center;
}
.customer-body-cell-bd-ft span{
    background-color: #eee;
    padding: 3px 5px;
    border-radius: 3px;
}
.customer-ltcontent{
    display: flex;
    position: relative;
}
.customer-ltcontent-jt{
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    top: 15px;
}
.customer-ltcontent-box{
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    min-height: 20px;
    margin-left: 5px;
}

.customer-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
}
.customer-footer-hd{
    flex: 1;
    padding-right: 10px;
}
.customer-footer-bd{

}
.customer-xuanzhuan{
        direction: rtl;
}
.customer-xuanzhuan .customer-body-cell-bd-ft{
    direction: ltr;
}
.customer-xuanzhuan .customer-ltcontent-box{
    margin-right: 5px;
    margin-left: 0;
    background-color: #00CB00;
    text-align: left;
}
.customer-xuanzhuan .customer-ltcontent-jt{
    right: 0;
    background-color: #00CB00;
}