.worknews-drawer{
    display: flex;
    flex-flow: column;
    background-color: var(--theme-body-bg);
    height: 100%;
}
.worknews-drawer-hd{
    flex: 1;
    overflow-y: auto;
}
.worknews-drawer-bd{
}
.worknews-drawer-bd-hd{
    padding: 10px;
}
.worknews-drawer-bd-state{
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px -2px 8px #ccc;
    border-radius: 5px;
}
.worknews-drawer-bd-state-title{
    font-size: 18px;
    font-weight: bold;
}
.worknews-drawer-bd-state-title.st{
    color: #ED6A18;
}
.worknews-drawer-bd-state-title.su{
    color: var(--theme-default-font-color);
}
.worknews-drawer-bd-state-title.er{
    color: #ff0000;
}
.worknews-drawer-bd-state-content{
    font-size: 15px;
    background-color: #fff;
    padding-top: 10px;
    font-weight: bold;
}
.worknews-drawer-bd-cells{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #eee;
    background-color: #fff;
    padding: 10px;
}
.worknews-drawer-bd-cell{
    padding-left: 10px;
}
.worknews-page{
    background-color: var(--theme-body-bg);
}
.worknews-header{
    padding: 10px;
    background-color: #fff;
}
.worknews-header-hd{
    font-size: 18px;
    font-weight: bold;
}
.worknews-header-bd{
    padding-top: 10px;
    display: flex;
}
.worknews-header-bd-hd{
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #ff0000;
}
.worknews-header-bd-bd{

}
.worknews-header-bd-bd span{
    padding: 0 10px;
    border-right: 1px solid #ccc;
}
.worknews-header-bd-bd span:last-child{
    border: none;
}
.worknews-header-ft{
    padding-top: 5px;
}
.worknews-header-ft .tagcell{
    margin-right: 10px;
    margin-top: 5px;
    background-color: #eee !important;
    padding: 7px;
    border-radius: 3px !important;
}
.worknews-body{
    padding: 10px;
}
.worknews-body-cells{
    border-radius: 10px;
    background-color: #fff;
    padding: 0px 10px;
}
.worknews-body-cell{
    padding: 10px 0
}
.worknews-body-cell-hd{
    font-weight: bold;
    font-size: 14px;
}
.worknews-body-cell-bd{
    padding-top: 5px;
    padding-left: 5px;
}
.worknews-body-cell-ft{
    padding-top: 5px;
    padding-left: 5px;
}
.worknews-body-cell-bd .tagcell{
    background-color: #ddd !important;
    color: #444 !important;
    padding: 7px !important;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 3px !important;
}
.worknews-footer{
    padding: 0 10px;
}
.worknews-footer-title{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}
.worknews-footer-content{
    padding: 10px 5px;
    border-radius: 3px;
    background-color: #fff;
    font-size: 15px;
    word-wrap:break-word !important;
}
.worknews-note{
    text-align: center;
    padding: 20px;
}
.userlist-lq-cell{
    display: flex;
    align-items: center;
    padding:10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}
.userlist-lq-cell-hd{
    padding-right: 10px;
}
.userlist-lq-cell-hd img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.userlist-lq-cell-bd{
    flex: 1;
}
.userlist-lq-cell-ft{
    padding: 0px 20px;
    font-size: 18px;
}
.userlist-lq-content{
    text-align: center;
    padding: 20px 0;
    color: #999;
}
.workuserlogs-cell{
    display: flex;
    width: 100%;
    align-items: center;
}
.workuserlogs-cell-hd{
    flex: 1;
    font-weight: bold;
    padding-right: 15px;
}
.workuserlogs-cell-bd{

}