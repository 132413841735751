.page-main{
    display: flex;
    min-height: 100vh;
}
.page-left{
    background: #202033;
    display: flex;
}
.left-nav{
    flex: 1;
    width: 105px;
    display: flex;
    flex-flow: column;
    background-color: #202033;
    color: #D2D2D6;
}
.left-nav-header{
    padding: 20px 0;
    text-align: center;

}
.left-nav-body{
    flex: 1;
}
.left-nav-body-cells{

}
.left-nav-body-cell{
    padding: 0px 10px;
    margin: 5px 0;
}
.left-nav-body-cell.active{
    background-color: #fff;
    color: #333;
}
.left-nav-body-cell.active .left-nav-body-cell-box{
    color: #333;
}
.left-nav-body-cell.active .left-nav-body-cell-box:hover{
    background: none;
}
.left-nav-body-cell-box{
    display: flex;
    padding: 10px 0;
    align-items: center;
    color: #D2D2D6;
    border-radius: 3px;
}
.left-nav-body-cell-box:hover{
    background-color: #313143;
}
.left-nav-body-cell-hd{
    padding: 0 10px;
    font-size: 20px;
}
.left-nav-body-cell-bd{
    font-size: 14px;
}
.left-nav-footer{
    padding:10px;
    text-align: center;
}
.left-nav-footer-cells{
    display: flex;
    font-size: 20px;
}
.left-nav-footer-cell{
    flex: 1;
    text-align: center;
}
.left-nav-footer-cell span{
    cursor: pointer;
    padding: 5px 10px;
}
.left-nav-footer-cell a{
    color: #fff;
}
.left-nav-footer-cell span:hover{
    background-color: #313143;
    border-radius: 3px;
}
.left-nav-pop{
}
.left-nav-pop .zent-pop-v2-inner{
    background-color: #8A8F99 !important;
    color: #fff !important;
    padding: 10px !important;
}
.left-nav-pop .zent-pop-v2-arrow{
    background-color: #8A8F99 !important;
}
.left-nav-pop-cells{
    width: 100px;
}
.left-nav-pop-cell{
    padding: 5px;
    margin: 5px 0;
}
.left-nav-pop-cell:hover{
    background-color: #9398A1;
    cursor: pointer;
}
.left-nav-pop-cell a{
    color:#fff;
}
.left-menu{
    background-color: #fff;
    width: 120px;
    overflow: hidden;
    padding: 0px 10px;
}
.left-menu-header{
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid  #E0E0E0;
}
.left-menu-body{}
.left-menu-body-cells{
    padding: 10px;
    font-size: 14px;
}
.left-menu-body-cell{
    display: flex;
    margin: 5px 0;
    padding: 10px;
    color: #333;
    font-size: 13px;
    border-radius: 3px;
}
.left-menu-body-cell.active{
    background-color: #EDF2FC;
    color: #155BD4;
}
.left-menu-body-cell:hover{
    background-color: #F7F7F7;
    color: #333;
}
.page-right{
    flex: 1;
    padding: 20px;
    background-color: #F7F8FA;
}
.page-right-header{
    padding-bottom: 20px;
}
.page-right-header-cells{
    display: flex;
    align-items: center;
}
.page-right-header-cell:last-child{
    color: #333;
}
.page-right-header-cell{
    display: flex;
    color: #999;
    align-items: flex-end;
}
.page-right-header-cell a{
    color: #999;
}
.page-right-header-cell a:hover{
    color: #155BD4;
}
.page-right-header-cell-hd{
}
.page-right-header-cell-bd{
    padding: 0px 10px 0;
}
.page-right-body{
    padding: 20px;
    background-color: #fff;
}

.page-right-body-hd{
    display: flex;
    padding-bottom: 20px;
}
.page-right-body-hd-hd{
    flex: 1;
}
.page-right-body-bd .zent-grid-tr{

}